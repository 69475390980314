export default [
  {
    path: "/crm/userquery",
    name: "user-query",
    component: () => import("@/views/crm/UserQuery.vue"),
    meta: {
      pageTitle: "User Query",
      breadcrumb: [
        {
          text: "User Query",
          active: true
        }
      ]
    }
  },
    {
    path: "/task-master",
    name: "task-master",
    component: () => import("@/views/taskMaster/TaskMasterList.vue"),
    meta: {
      pageTitle: "Task Master List"
    }
  },
  {
  path: "/daily-task",
  name: "daily-task",
  // component: () => import("@/views/dailyTaskList/DailyTaskList.vue"),
  component: () => import("@/views/dailyTask/DailyTaskList.vue"),
  meta: {
    pageTitle: "Daily Task"
  }
}, 
 {
  path: "/member-claim",
  name: "member-claim",
  // component: () => import("@/views/dailyTaskList/DailyTaskList.vue"),
  component: () => import("@/views/claimTask/ClaimTaskList.vue"),
  meta: {
    pageTitle: "Claim Task"
  }
},
  {
    path: "/task-master/create",
    name: "create-task-master",
    component: () => import("@/views/taskMaster/components/TaskDetail.vue"),
    meta: {
      pageTitle: "Task Detail",
      breadcrumb: [
        {
          text: "Create",
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/config-crm",
  //   name: "config-crm",
  //   component: () => import("@/views/configCRM/ConfigCRMList.vue"),
  //   meta: {
  //     pageTitle: "Configuration CRM",
  //   }
  // },
  {
    path: "/crm-user-config",
    name: "crm-user-config",
    component: () => import("@/views/configCRM/CRMUserConfigList.vue"),
    meta: {
      pageTitle: "CRM User Config",
    }
  },
  {
    path: "/crm-user-config/create",
    name: "crm-user-config-create",
    component: () => import("@/views/configCRM/components/CrmUserConfigDetail.vue"),
    meta: {
      pageTitle: "CRM User Config",
      breadcrumb: [
        {
          text: "Create",
          active: true
        }
      ]
    }
  },  {
    path: "/crm-user-config/edit/:id",
    name: "crm-user-config-edit",
    component: () => import("@/views/configCRM/components/CrmUserConfigDetail.vue"),
    props: true,
    meta: {
      // requiresAuth: true,
      pageTitle: "CRM User Config",
      breadcrumb: [
        {
          text: "Create",
          active: true
        }
      ]
    }
  },{
    path: "/crm-user-config/member/:username",
    name: "crm-user-config-member",
    component: () => import("@/views/configCRM/MemberClaimList.vue"),
    props: true,
    meta: {
      // requiresAuth: true,
      pageTitle: "Member Claims",
      breadcrumb: [
        {
          text: "List",
          active: true
        }
      ]
    }
  },{
    path: "/crm-user-config/report/:username",
    name: "crm-user-config-report",
    component: () => import("@/views/configCRM/DailyCRMReport.vue"),
    props: true,
    meta: {
      // requiresAuth: true,
      pageTitle: "Daily Report CRM Claims",
      breadcrumb: [
        {
          text: "List",
          active: true
        }
      ]
    }
  },{
    path: "/crm-user-report",
    name: "crm-user-report",
    component: () => import("@/views/crmUserReport/CRMUserReport.vue"),
    meta: {
      pageTitle: "CRM Member Deposit Report",
      breadcrumb: [
        {
          text: "List",
          active: true
        }
      ]
    }
  },
];
